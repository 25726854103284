import React, { memo } from "react"
import { propEq } from "ramda"
import { connect } from "react-redux"
import Select from "react-select"
import { localized } from "js/includes/common/utils"
import { ControlLabel } from "react-bootstrap"
import { StyledFormGroup, StyledRow, StyledCol, StyledFormControl } from "js/includes/components/Styled/Form"
import { getTimeOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { statusRowRenderer } from "js/includes/ticketing/editor/tabHeaderActions/general"

const ConditionReset = memo(({ readonly, values, onChange, validation, statusList }) => {
  const { resetStatus, resetTimeUnit, resetTimeValue } = values
  const statusOptions = getStatusOptions(statusList, { hideClose: true })

  return (
    <>
      <StyledFormGroup
        id="form-group-condition-reset-status-container"
        marginBottom={2}
        validationState={validation.success.resetStatus === false ? "error" : null}
      >
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Status")} *
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={2}>
          <SearchableDropDown
            keepDropdownInView
            width="100%"
            options={statusOptions}
            value={statusOptions.find(propEq("value", resetStatus))}
            onSelect={status => onChange("resetStatus", status.value)}
            disabled={readonly}
            searchableKey="labelText"
            rowRenderer={statusRowRenderer}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledRow>
        <StyledCol xs={6}>
          <StyledFormGroup id="form-group-condition-reset-addTimeEntry-container" flex={1}>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {localized("Add Time Entry")}
            </StyledCol>
            <StyledCol marginTop={2} xs={12} display="inline-block" flexDirection="column">
              <StyledRow display="flex" alignItems="center">
                <StyledCol xs={6}>
                  <StyledFormControl
                    id="form-control-condition-reset-time-input"
                    value={resetTimeValue}
                    onChange={e => onChange("resetTimeValue", e.target.value)}
                    min="0"
                    type="number"
                    disabled={readonly}
                  />
                </StyledCol>
                <StyledCol xs={6}>
                  <Select
                    id="select-condition-reset-time"
                    className="flex-full"
                    value={getTimeOptions().find(o => resetTimeUnit === o.value)}
                    onChange={s => onChange("resetTimeUnit", s.value)}
                    options={getTimeOptions()}
                    isDisabled={readonly}
                  />
                </StyledCol>
              </StyledRow>
            </StyledCol>
          </StyledFormGroup>
        </StyledCol>
      </StyledRow>
    </>
  )
})

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(ConditionReset)
