import { memo, useEffect, useMemo } from "react"
import { propEq, pluck } from "ramda"
import { connect } from "react-redux"
import { localized } from "js/includes/common/utils"
import { ControlLabel } from "react-bootstrap"
import { StyledFormGroup, StyledCol } from "js/includes/components/Styled/Form"
import {
  getRetriggerOptions,
  getSeverityOptions,
  getPriorityOptions,
} from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import TagsInput from "js/includes/ticketing/common/TagsInput"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { statusRowRenderer } from "js/includes/ticketing/editor/tabHeaderActions/general"

const ConditionRetrigger = memo(
  ({ readonly, validation, onChange, values, validateFormFields, tagsData, statusList }) => {
    const { retriggerStatus, retriggerTags, retriggerPriority, retriggerSeverity, createNewTicket } = values

    const { priorityOptions, severityOptions, statusOptions } = useMemo(() => {
      const useCurrent = { value: null, label: localized("ticketing.useCurrent") }

      return {
        statusOptions: getStatusOptions(statusList, { hideClose: true }),
        priorityOptions: [useCurrent, ...getPriorityOptions()],
        severityOptions: [useCurrent, ...getSeverityOptions()],
      }
    }, [statusList])

    useEffect(() => {
      createNewTicket && validateFormFields(["retriggerStatus"])
    }, [createNewTicket, validateFormFields])

    return (
      <>
        <StyledFormGroup id="form-group-condition-retrigger-action-container" marginBottom={2}>
          <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
            {localized("Action")}
          </StyledCol>
          <StyledCol xs={12} flexDirection="column" marginTop={2}>
            <SearchableDropDown
              keepDropdownInView
              width="100%"
              options={getRetriggerOptions()}
              value={getRetriggerOptions().find(propEq("value", createNewTicket))}
              onSelect={status => onChange("createNewTicket", status.value)}
              isSearchable={false}
              disabled={readonly}
            />
          </StyledCol>
        </StyledFormGroup>

        {!createNewTicket && (
          <>
            <StyledFormGroup
              id="form-group-condition-retrigger-status-container"
              marginBottom={2}
              validationState={validation.success.retriggerStatus === false ? "error" : null}
            >
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Status")} *
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <SearchableDropDown
                  keepDropdownInView
                  width="100%"
                  searchableKey="labelText"
                  options={statusOptions}
                  value={statusOptions.find(propEq("value", retriggerStatus))}
                  onSelect={status => onChange("retriggerStatus", status.value)}
                  isSearchable={false}
                  disabled={readonly}
                  rowRenderer={statusRowRenderer}
                />
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup id="form-group-condition-retrigger-addTags-container" marginBottom={2}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Add Tags")}
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <TagsInput
                  selected={pluck("value", retriggerTags || [])}
                  disableEditor={readonly}
                  onChange={selected => onChange("retriggerTags", selected)}
                  tags={tagsData?.tags?.map(tag => ({ label: tag.name, value: tag.name }))}
                />
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup id="form-group-condition-retrigger-priority-container" marginBottom={2}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Priority")}
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <SearchableDropDown
                  keepDropdownInView
                  width="100%"
                  options={priorityOptions}
                  value={priorityOptions.find(propEq("value", retriggerPriority))}
                  onSelect={priority => onChange("retriggerPriority", priority.value)}
                  isSearchable={false}
                  disabled={readonly}
                />
              </StyledCol>
            </StyledFormGroup>

            <StyledFormGroup id="form-group-condition-retrigger-severity-container">
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Severity")}
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <SearchableDropDown
                  keepDropdownInView
                  width="100%"
                  options={severityOptions}
                  value={severityOptions.find(propEq("value", retriggerSeverity))}
                  onSelect={priority => onChange("retriggerSeverity", priority.value)}
                  isSearchable={false}
                  disabled={readonly}
                />
              </StyledCol>
            </StyledFormGroup>
          </>
        )}
      </>
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(ConditionRetrigger)
