import { assoc } from "ramda"

export const initialState = {
  hostname: "",
  enabled: false,
  acceloModalStep: "haveAccount",
  haveAccount: false,
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case "INIT_ACCELO":
      return action.payload
    case "SET_ACCELO_HOSTNAME":
      return assoc("hostname", action.hostname, state)
    case "SET_ACCELO_ENABLED":
      return assoc("enabled", action.enabled, state)
    case "INIT_ACCELO_HOSTNAME":
      return assoc("hostname", "", state)
    case "SET_ACCELO_MODAL_STEP":
      return assoc("acceloModalStep", action.step, state)
    case "SET_ACCELO_HAVE_ACCOUNT":
      return assoc("haveAccount", action.haveAccount, state)
    default:
      return state
  }
}
