import { memo, useMemo } from "react"
import { propEq, pluck } from "ramda"
import { connect } from "react-redux"
import { ControlLabel } from "react-bootstrap"
import { Checkbox } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import { StyledFormGroup, StyledCol } from "js/includes/components/Styled/Form"
import { getPriorityOptions, getSeverityOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import TagsInput from "js/includes/ticketing/common/TagsInput"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { statusRowRenderer } from "js/includes/ticketing/editor/tabHeaderActions/general"

const sizer2 = sizer(2)

const TicketCreate = memo(({ readonly, onChange, tagsData, values, validation, ticketFormsOptions, statusList }) => {
  const { ticketFormId, tags, priority, severity, status, useOwnerAsRequester } = values

  const { priorityOptions, severityOptions, statusOptions } = useMemo(() => {
    const usePolicy = { value: null, label: localized("ticketing.usePolicy") }

    return {
      priorityOptions: [usePolicy, ...getPriorityOptions()],
      severityOptions: [usePolicy, ...getSeverityOptions()],
      statusOptions: getStatusOptions(statusList, { hideClose: true }),
    }
  }, [statusList])

  return (
    <>
      <StyledFormGroup
        id="form-group-ticket-create-form-container"
        marginBottom={sizer2}
        validationState={validation.success.ticketFormId === false ? "error" : null}
      >
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Form")} *
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <SearchableDropDown
            keepDropdownInView
            width="100%"
            options={ticketFormsOptions}
            value={ticketFormsOptions.find(propEq("value", ticketFormId))}
            onSelect={form => onChange("ticketFormId", form.value)}
            disabled={readonly}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledFormGroup id="form-group-ticket-create-tags-container">
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Tags")}
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <TagsInput
            selected={pluck("value", tags || [])}
            disableEditor={readonly}
            onChange={selected => onChange("tags", selected)}
            tags={tagsData?.tags?.map(tag => ({ label: tag.name, value: tag.name }))}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledFormGroup
        id="form-group-ticket-create-status-container"
        marginBottom={sizer2}
        validationState={validation.success.status === false ? "error" : null}
      >
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Status")} *
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <SearchableDropDown
            keepDropdownInView
            width="100%"
            options={statusOptions}
            value={statusOptions.find(propEq("value", status))}
            onSelect={status => onChange("status", status.value)}
            disabled={readonly}
            searchableKey="labelText"
            rowRenderer={statusRowRenderer}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledFormGroup
        id="form-group-ticket-create-priority-container"
        marginBottom={sizer2}
        validationState={validation.success.priority === false ? "error" : null}
      >
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Priority")}
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <SearchableDropDown
            keepDropdownInView
            width="100%"
            options={priorityOptions}
            value={priorityOptions.find(propEq("value", priority))}
            onSelect={priority => onChange("priority", priority.value)}
            disabled={readonly}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledFormGroup
        id="form-group-ticket-create-severity-container"
        marginBottom={sizer2}
        validationState={validation.success.severity === false ? "error" : null}
      >
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Severity")}
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <SearchableDropDown
            keepDropdownInView
            width="100%"
            options={severityOptions}
            value={severityOptions.find(propEq("value", severity))}
            onSelect={priority => onChange("severity", priority.value)}
            disabled={readonly}
          />
        </StyledCol>
      </StyledFormGroup>

      <StyledFormGroup id="form-group-ticket-create-owner-as-requester-container">
        <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
          {localized("Use Device's User as Requester")}
        </StyledCol>
        <StyledCol xs={12} flexDirection="column" marginTop={sizer2}>
          <Checkbox
            checked={useOwnerAsRequester}
            disabled={readonly}
            onChange={() => onChange("useOwnerAsRequester", !useOwnerAsRequester)}
          />
        </StyledCol>
      </StyledFormGroup>
    </>
  )
})

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(TicketCreate)
