import React, { memo, useCallback } from "react"
import { useTheme } from "@emotion/react"
import { Badge, Link, Tags, Tooltip } from "@ninjaone/components"
import * as ninjaIcons from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { cellTypes } from "../utils"
import Switch from "./Switch"
import Text from "./Text"
import ProgressBarCell from "./ProgressBarCell"
import LazyRenderer from "./LazyRenderer"
import TextWithTooltip from "./TextWithTooltip"
import CopyToClipboard from "./CopyToClipboard"
import StickyPopover from "./StickyPopover"
import { ImageResource } from "./ImageResource"

const cellTypeOptions = {
  [cellTypes.ANCHOR]: ({ children, className = "", ...rest }) => (
    <Link {...{ ...rest, className: `${className} data-table-interactive-cell` }}>{children}</Link>
  ),
  [cellTypes.BUTTON_LINK]: ({ children, className = "", ...rest }) => (
    <button {...{ ...rest, className: `${className} data-table-button-link data-table-interactive-cell` }}>
      {children}
    </button>
  ),
  [cellTypes.ICON]: props => ninjaIcons[props.svgIconName]?.(props), // has to be an SVG
  [cellTypes.TEXT]: React.Fragment,
  [cellTypes.SWITCH]: ({ className = "", ...rest }) => (
    <Switch {...{ ...rest, className: `${className} data-table-interactive-cell` }} />
  ),
  [cellTypes.TAGS]: props => <Tags {...{ ...props, renderFullVersion: false }} />,
  [cellTypes.LAZY_RENDERER]: props => <LazyRenderer {...props} />,
  [cellTypes.STICKY_POPOVER]: props => <StickyPopover {...props} />,
  [cellTypes.PROGRESS]: props => <ProgressBarCell {...props} />,
}

const getCellValue = ({ value, required }) => {
  if (value === null || value === undefined) return "-"
  return required ? `${value} *` : value
}

const Cell = ({ column, value, mouseEnteredRow, originalRow, tableContainerWidth }) => {
  // WARNING please be careful when adding any code here as slow render times with
  // this component can drastically affect the DataTable performance

  // Guidelines
  // - No styled components
  // - If you need to render a component (like a dropdown, tooltip etc..) use mouseEnteredRow to initially a show "dumb" version of the component
  // - No FontAwesome icons (use svg only)
  // - No looping
  // - Keep the Cell DOM tree size to a minimum
  // - If anything can be computed ahead of time do it and pass it in as a prop

  const theme = useTheme()
  const {
    textWrap,
    textWrapLineLimit,
    getCellTypeProps,
    getCellInlineTags,
    getCellBadgeProps,
    getDescription,
    getImg,
    getIconName,
    getIconProps,
    bold,
    isCopyable,
    secondaryIconProps,
    maxWidth: _maxWidth,
    minWidth: _minWidth,
    id: columnId,
    tooltipAccessor,
    ignoreTooltip,
    getCopyableDropdownProps,
    getCellSizeProps,
  } = column

  const cellSizeProps = getCellSizeProps?.({ tableContainerWidth, originalRow }) || {}
  const maxWidth = cellSizeProps.maxWidth ?? _maxWidth
  const minWidth = cellSizeProps.minWidth ?? _minWidth
  const { type, props, backgroundColor, iconTooltipLabel, error, required, rightAlign } =
    getCellTypeProps?.(originalRow) || {}
  const cellInlineTags = getCellInlineTags?.(originalRow) ?? null
  const CellType = cellTypeOptions[type] ?? cellTypeOptions.Text
  const description = getDescription?.(originalRow)
  const img = getImg?.(originalRow)
  const iconName = getIconName?.(originalRow)
  const iconProps = getIconProps?.(originalRow)
  const Icon = iconName ? ninjaIcons[iconName] : null
  const leadingIconToolTipLabel = iconProps?.getTooltipLabel?.(originalRow)
  const secondaryIconName = secondaryIconProps?.getIconName?.(originalRow)
  const secondaryIconColor = secondaryIconProps?.getIconColor?.()
  const SecondaryIcon = secondaryIconName ? ninjaIcons[secondaryIconName] : null
  const secondaryIconToolTipLabel = secondaryIconProps?.getIconTooltipLabel?.(originalRow)
  const secondaryIconToolTipVariant = secondaryIconProps?.getIconTooltipVariant?.(originalRow)
  const copyableDropdownOptions = getCopyableDropdownProps?.(originalRow)?.options
  const image = img?.attachmentId ? (
    <ImageResource size={img.size} attachmentId={img.attachmentId} defaultIconName={img.defaultIconName} />
  ) : img ? (
    <img height={img.size ?? "20"} width={img.size ?? "20"} src={img.src} alt="" />
  ) : null
  const graphic = Icon ? <Icon {...iconProps} /> : image ? image : null
  const hasMaxWidth = maxWidth && maxWidth !== Number.MAX_SAFE_INTEGER
  const cellValue = getCellValue({ value, required })
  const textHasTooltip = !ignoreTooltip && (tooltipAccessor || hasMaxWidth)

  const getToolTip = useCallback(() => {
    if (tooltipAccessor) {
      return typeof tooltipAccessor === "function" ? tooltipAccessor(originalRow) : tooltipAccessor
    }
    return null
  }, [originalRow, tooltipAccessor])

  const text = textHasTooltip ? (
    <TextWithTooltip
      {...{
        textWrap,
        textWrapLineLimit,
        extraClassName: bold ? "bold" : "",
        value: cellValue,
        mouseEnteredRow,
        tooltip: getToolTip(),
        color: error ? "red" : "",
      }}
    />
  ) : (
    <Text
      {...{
        extraClassName: `${bold ? "bold" : ""}${ignoreTooltip ? " show-ellipsis" : ""}`,
        value: cellValue,
        color: error ? "red" : "",
      }}
    />
  )

  const getDescriptionText = useCallback(() => {
    if (!description) {
      return null
    } else {
      return hasMaxWidth ? (
        <TextWithTooltip
          {...{
            textWrap,
            textWrapLineLimit,
            value: description,
            mouseEnteredRow,
            extraClassName: "description",
          }}
        />
      ) : (
        <Text {...{ textWrap, textWrapLineLimit, extraClassName: "description", value: description }} />
      )
    }
  }, [description, mouseEnteredRow, hasMaxWidth, textWrap, textWrapLineLimit])

  const icon =
    iconTooltipLabel && mouseEnteredRow ? (
      <Tooltip label={iconTooltipLabel}>
        <CellType {...props} />
      </Tooltip>
    ) : (
      <CellType {...props} />
    )

  const renderCellBadge = () => {
    const cellBadgeProps = getCellBadgeProps?.(originalRow)
    if (!cellBadgeProps) return null

    const badge = (
      <Badge
        backgroundColor={theme.colorBackgroundAccentNeutral}
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={[0, sizer(2)]}
        width="initial"
        height="15px"
        bold
        {...cellBadgeProps}
      />
    )

    if (cellBadgeProps.tooltipLabel && mouseEnteredRow) {
      return <Tooltip label={cellBadgeProps.tooltipLabel}>{badge}</Tooltip>
    }
    return badge
  }

  const isRightAligned =
    type === cellTypes.DATE || type === cellTypes.CURRENCY || type === cellTypes.NUMBER || rightAlign

  const Graphic = () => graphic && <div className="data-table-graphic-wrapper">{graphic}</div>

  return (
    <td
      tabIndex={0}
      data-no-pad={columnId === "actions"}
      data-error={error}
      data-test-column-id={columnId}
      style={{
        ...(backgroundColor && { backgroundColor }),
        ...(type === cellTypes.LAZY_RENDERER && cellSizeProps.height && { height: cellSizeProps.height }),
      }}
      className={!value ? "no-value" : ""}
    >
      <div
        className={`data-table-cell ${isRightAligned ? "right-aligned" : ""} ${img ? "gap-4" : "gap-2"}`}
        style={{
          ...(props?.isCenter && (type === cellTypes.ICON || cellValue === "-") && { paddingRight: "0" }),
          ...(hasMaxWidth && { maxWidth }),
          ...(minWidth && { minWidth }),
          ...(isRightAligned && { justifyContent: "end" }),
          ...(type === cellTypes.LAZY_RENDERER && { width: "max-content" }),
          ...(typeof cellSizeProps.paddingRight !== "undefined" && { paddingRight: cellSizeProps.paddingRight }),
        }}
      >
        {mouseEnteredRow && leadingIconToolTipLabel && graphic ? (
          <Tooltip label={leadingIconToolTipLabel}>
            <Graphic />
          </Tooltip>
        ) : (
          <Graphic />
        )}
        <div
          className={`data-table-text-wrapper ${!textHasTooltip && !ignoreTooltip ? "flex-shrink-0" : ""}`}
          style={{ ...(props?.isCenter && { margin: "0 auto" }) }}
        >
          {!type || type === "Text" ? (
            text
          ) : type === cellTypes.ICON ? (
            icon
          ) : type === cellTypes.TAGS ? (
            <CellType {...{ ...props, mouseEnteredRow }} />
          ) : (
            <CellType
              {...{
                ...props,
                ...(type === cellTypes.SWITCH && { mouseEnteredRow }),
                ...(type === cellTypes.LAZY_RENDERER && { mouseEnteredRow, tableContainerWidth }),
              }}
            >
              {text}
            </CellType>
          )}
          {getDescriptionText()}
        </div>
        {cellInlineTags && <Tags {...{ labels: cellInlineTags, mouseEnteredRow: true, renderFullVersion: false }} />}
        {renderCellBadge()}
        {SecondaryIcon && (
          <div className="secondary-icon-container">
            {mouseEnteredRow ? (
              <Tooltip
                label={secondaryIconToolTipLabel}
                {...(secondaryIconToolTipVariant && { variant: secondaryIconToolTipVariant })}
              >
                <span className="cell-span" data-visible-on-row-hover>
                  <SecondaryIcon
                    {...{ ...(secondaryIconColor ? { color: secondaryIconColor } : { color: "colorTextWeak" }) }}
                  />
                </span>
              </Tooltip>
            ) : null}
          </div>
        )}

        {isCopyable && value && (mouseEnteredRow ? <CopyToClipboard {...{ value, copyableDropdownOptions }} /> : null)}
      </div>
    </td>
  )
}

export default memo(Cell)
